<template>
    <div class="show-user-resume" v-if="showResume">
        <div class="u-base-info">
            <div class="u-base-desc">
                <div id="printMe">
                    <div class="resume-user-info flex">
                        <div class="info-primary">
                            <div class="i-primary-name">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.name}}</div>
                            <div class="i-primary-label">
                                <p class="first">
                                    <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.age}}岁</span>
                                    <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.gender}}</span>
                                    <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.education}}</span>
                                    <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.workExperience}}工作经验</span>
                                    <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.nowStatus}}</span>
                                </p>
                                <p>
                                    <span class="prev-line">手机：{{(resumeInfo.resumeDO&&resumeInfo.resumeDO.linkerPhone)||'无'}}</span>
                                    <span class="prev-line">微信：{{(resumeInfo.resumeDO&&resumeInfo.resumeDO.vxNumber)||'无'}}</span>
                                    <span class="prev-line">应届：{{(resumeInfo.resumeDO&&resumeInfo.resumeDO.isStudent)?'是':'否'}}</span>
                                </p>
                            </div>
                        </div>
                        <div class="info-img">
                            <img :src="(resumeInfo.resumeDO&&resumeInfo.resumeDO.picUrl)||require('../../../assets/img/company/avatar.png')" alt="">
                        </div>
                    </div>
                    <div class="hope-job">
                        <div class="hope-title base-title">期望职位</div>
                        <div class="hope-base-content">
                            <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.expectPost}}</span>
                            
                            <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.expectSalary}}</span>
                            <span class="prev-line">{{resumeInfo.resumeDO&&resumeInfo.resumeDO.industry}}</span>
                        </div>
                        <div class="yixiang">
                            <em>第一意向地：{{resumeInfo.resumeDO&&resumeInfo.resumeDO.liveAddress}}</em>
                            <em>第二意向地：{{resumeInfo.resumeDO&&resumeInfo.resumeDO.liveAddress1}}</em>
                            <em>第三意向地：{{resumeInfo.resumeDO&&resumeInfo.resumeDO.liveAddress2}}</em>
                                
                            
                        </div>
                    </div>
                    <div class="work-base-list" v-if="resumeInfo.resumeWorkExperienceDOS.length>0">
                        <div class="base-work-title base-title">工作经历</div>
                        <div class="base-work-list-w">
                            <div class="base-work-item" v-for="(item, index) in resumeInfo.resumeWorkExperienceDOS" :key="`resumeWorkExperienceDOS${index}`">
                                <div class="item-info-text flex">
                                    <div class="i-info-name text-overflow">{{item.companyName}}</div>
                                    <div class="i-info-time">{{item.startTime}} 至 {{item.endTime}}</div>
                                </div>
                                <div class="item-info-other">
                                    <div>担任职位：{{item.position}}</div>
                                    <div>薪资水平：{{item.salary}}</div>
                                    <pre>{{item.dutyContent}}</pre>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b-project-list" v-if="resumeInfo.resumeProjectExperienceDO.length>0">
                        <div class="project-list-title base-title">项目经历</div>
                        <div class="project-list-w">
                            <div class="project-item" v-for="(item, index) in resumeInfo.resumeProjectExperienceDO" :key="`resumeProjectExperienceDO${index}`">
                                <div class="item-info-text flex">
                                    <div class="i-info-name text-overflow">{{item.projectName}}</div>
                                    <div class="i-info-time">{{item.startTime}} 至 {{item.endTime}}</div>
                                </div>
                                <div class="item-info-other">
                                    <div>担任职位：{{item.position}}</div>
                                    <pre>{{item.projectDesc}}</pre>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b-education-list" v-if="resumeInfo.resumeEductionExperienceDO.length>0">
                        <div class="education-list-title base-title">教育经历</div>
                        <div class="e-list-w">
                            <div class="e-list-item" v-for="(item, index) in resumeInfo.resumeEductionExperienceDO" :key="`resumeEductionExperienceDO${index}`">
                                <div class="e-item-info-text flex">
                                    <div class="e-info-name">{{item.schoolName}}</div>
                                    <div class="e-info-time">{{item.startTime}} 至 {{item.endTime}}</div>
                                </div>
                                <div class="e-info-desc">
                                    <span class="prev-line e-bold">{{item.major}}</span>
                                    <!-- <span class="prev-line e-bold">本科</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b-aboult-self" v-if="resumeInfo.resumeDO&&resumeInfo.resumeDO.selfEvaluation">
                        <div class="b-aboult-self-title base-title">自我评价</div>
                        <div class="info-d-content">
                            <pre>{{resumeInfo.resumeDO.selfEvaluation}}</pre>
                        </div>
                    </div>
                </div>
                <div class="b-down-load flex" >
                    <el-button class="print-btn" type="primary" v-print="printObj" @click="printResume" :loading="loadingStatus">{{loadingText}}</el-button>
                </div>
            </div>
            <div class="u-base-close" @click="closeUserResumeBox">×</div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        showResume: {
            type: Boolean,
            default: false
        },
        resumeInfo: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    data() {
        return {
            printObj: {
                id: "printMe",
                // extraCss: 'https://www.google.com,https://www.google.com',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            loadingStatus: false,
            loadingText: '打印简历'
        }
    },
    methods: {
        printResume() {
            this.loadingStatus = true
            this.loadingText = '正在生成简历，请稍后'
            setTimeout(() => {
                this.loadingStatus = false
                this.loadingText = '打印简历'
            }, 1000)
        },
        closeUserResumeBox() {
            this.$emit('closeUserResume')
        }
    },
}
</script>
<style lang="scss" scoped>
    @import "../../../assets/css/global.scss";
    pre{
        color: #61687c;
        line-height: 22px;
    }
    .prev-line{
        &::before{
            margin: 0 26px;
            content: "";
            display: inline-block;
            vertical-align: middle;
            margin: -2px 26px 0 26px;
            width: 1px;
            height: 12px;
            background-color: #e0e0e0;
        }
        &:nth-child(1){
            &::before{
                margin: 0;
                content: "";
                display: inline-block;
                vertical-align: middle;
                background-color: #fff;
            }  
        }
    }
    .base-title{
        position: relative;
        padding-left: 12px;
        font-size: 18px;
        font-weight: 400;
        &::before{
            content: "";
            position: absolute;
            width: 3px;
            height: 14px;
            border-radius: 10px;
            background-color: $main;
            left: 0;
            top: 2px;
        }
    }
    .info-img{
        width: 100px;
        height: 100px;
        img{
            width: 100px;
            height: 100px;
        }
    }
    .show-user-resume{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10000;
        background: rgba($color: #000000, $alpha: .15);
        .u-base-info{
            position: relative;
            background: #fff;
            width: 800px;
            height: 100%;
            margin: 0 auto;
            // overflow-y: scroll;
            .u-base-desc{
                box-sizing: border-box;
                padding: 45px 50px;
                height: 100%;
                overflow: auto;
                .resume-user-info{
                    justify-content: space-between;
                    padding-bottom: 30px;
                    border-bottom: 1px solid #f3f3f3;
                    overflow: hidden;
                    .info-primary{
                        .i-primary-name{
                            font-size: 24px;
                            font-weight: 400;
                            margin-bottom: 20px;     
                        }
                        .i-primary-label{
                            p.first{
                                padding-bottom: 15px;
                            }
                            .prev-line{
                                &::before{
                                    margin: 0 26px;
                                    content: "";
                                    display: inline-block;
                                    vertical-align: middle;
                                    margin: -2px 19px 0 19px;
                                    width: 1px;
                                    height: 12px;
                                    background-color: #e0e0e0;
                                }
                                &:nth-child(1){
                                    &::before{
                                        margin: 0;
                                        content: "";
                                        display: inline-block;
                                        vertical-align: middle;
                                        background-color: #fff;
                                    }  
                                }
                            }
                        }
                    }
                    .info-img{
                        width: 70px;
                        height: 70px;
                        border-radius: 50%;
                        img{
                            width: 70px;
                            height: 70px;
                        }
                    }
                }
                .hope-job{
                    border-bottom: 1px solid #f3f3f3;
                    padding: 26px 0 15px;
                    min-height: 114px;
                    .hope-title{
                        position: relative;
                        padding-left: 12px;
                        font-size: 18px;
                        font-weight: 400;
                        &::before{
                            content: "";
                            position: absolute;
                            width: 3px;
                            height: 14px;
                            border-radius: 10px;
                            background-color: $main;
                            left: 0;
                            top: 2px;
                        }
                    }
                    .hope-base-content{
                        padding: 40px 0 0 15px;
                    }
                }
                .work-base-list{
                    border-bottom: 1px solid #f3f3f3;
                    padding: 26px 0 15px;
                    min-height: 114px;
                    .base-work-list-w{
                        margin-top: 30px;
                        .base-work-item{
                            margin-bottom: 15px;
                            .item-info-text{
                                align-items: center;
                                padding: 15px 0;
                                color: #61687c;
                                margin-bottom: 10px;
                                .i-info-name{
                                    max-width: 260px;
                                    color: #414a60;
                                }
                                .i-info-time{
                                    font-size: 12px;
                                    padding-left: 30px;
                                    position: relative;
                                    // top: 2px;
                                    color: #9fa3b0;
                                }
                            }
                            .item-info-other{
                                div{
                                    margin-bottom: 10px;
                                    color: #61687c;
                                }
                            }
                        }  
                    }
                }
                .b-project-list{
                    border-bottom: 1px solid #f3f3f3;
                    padding: 26px 0 15px;
                    min-height: 114px;
                    .project-list-w{
                        margin-top: 30px;
                        .project-item{
                            margin-bottom: 15px;
                            .item-info-text{
                                align-items: center;
                                padding: 15px 0;
                                color: #61687c;
                                margin-bottom: 10px;
                                .i-info-name{
                                    max-width: 260px;
                                    color: #414a60;
                                }
                                .i-info-time{
                                    font-size: 12px;
                                    padding-left: 30px;
                                    position: relative;
                                    // top: 2px;
                                    color: #9fa3b0;
                                }
                            }
                            .item-info-other{
                                div{
                                    margin-bottom: 10px;
                                    color: #61687c;
                                }
                            }
                        }  
                    }
                }
                .b-education-list{
                    border-bottom: 1px solid #f3f3f3;
                    padding: 26px 0 15px;
                    min-height: 114px;
                    .e-list-w{
                        margin-top: 30px;
                        .e-list-item{
                            margin-bottom: 30px;
                            .e-item-info-text{
                                margin-bottom: 15px;
                                justify-content: space-between;
                                .e-info-name{
                                    color: #414a60;
                                    font-weight: 700;
                                }
                                .e-info-time{
                                    color: #9fa3b0;
                                }
                            }
                            .e-info-desc{
                                .e-bold{
                                    font-weight: 700;
                                    color: #61687c;
                                }
                            }
                        }    
                    } 
                }
                .b-aboult-self{
                    border-bottom: 1px solid #f3f3f3;
                    padding: 26px 0 15px;
                    min-height: 114px;
                    .info-d-content{
                        margin-top: 30px; 
                    }
                }
                .b-down-load{
                    margin-top: 30px;
                    justify-content: center;
                    .print-btn{
                        width: 60%;
                    }
                    // .print-btn{
                    //     cursor: pointer;
                    //     width: 60%;
                    //     height: 36px;
                    //     line-height: 36px;
                    //     background: $main;
                    //     text-align: center;
                    //     color: #fff;
                    //     letter-spacing: 1px;
                    //     border-radius: 3px;
                    //     &:hover{
                    //         background: $hover;
                    //     }
                    // }
                }
            }
            .u-base-close{
                cursor: pointer;
                text-align: center;
                line-height: 32px;
                font-size: 18px;
                position: absolute;
                right: 0px;
                top: 0;
                width: 32px;
                height: 32px;
                background: #409eff; 
                color:#ffffff;
                &:hover{
                    background: #999;
                    color: #fff;
                } 
            }
        }
    }
</style>
<style lang="scss">
    @media print{
        #printMe p{
            margin-bottom: 10px;
        }
        #printMe .resume-user-info{
            justify-content: space-between;
        }
        #printMe pre{
            color: #61687c;
            line-height: 22px;
            margin-bottom: 30px;
        }
        #printMe.prev-line::before{
                margin: 0 26px;
                content: "";
                display: inline-block;
                vertical-align: middle;
                margin: -2px 26px 0 26px;
                width: 1px;
                height: 12px;
                background-color: #e0e0e0;
            &:nth-child(1){
                &::before{
                    margin: 0;
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    background-color: #fff;
                }  
            }
    }
        #printMe .i-primary-name{
            font-size: 24px;
            font-weight: 400;
            margin-bottom: 20px;  
        }
        #printMe .base-title{
            position: relative;
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 20px; 
        }
        #printMe .hope-base-content{
            padding-bottom: 40px;
        }
        #printMe .item-info-text{
            padding: 15px 0;
            color: #61687c;
            margin-bottom: 10px;
        }
        #printMe .i-info-name{
            margin-right: 30px;
        }
        #printMe .item-info-other div{
            margin-bottom: 10px;
            color: #61687c;
        }
        #printMe .item-info-other .e-info-desc{
            margin-bottom: 15px;
        }
        #printMe .e-list-item{
            margin-bottom: 30px;
        }
        #printMe .e-item-info-text{
            display: flex;
            justify-content: space-between;
             margin-bottom: 15px;
        }
        #printMe .e-item-info-text .e-info-name{
            color: #414a60;
            font-weight: 700;
        }
        #printMe .e-item-info-text .e-info-time{
            color: #9fa3b0;
        }
    }
    .yixiang{ padding: 8px 0; margin-top: 15px;}
    .yixiang em{ font-style:normal; margin-right: 10px;}
</style>